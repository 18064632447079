import { useEffect, useRef } from 'react';
import { supabase } from './supabaseClient';

const useSessionTracking = (userId: string | undefined) => {
  const lastActivityRef = useRef(new Date());

  useEffect(() => {
    if (!userId) return;

    const updateActivity = async (isEndSession: boolean = false) => {
      const { error } = await supabase.rpc('update_user_activity', { 
        user_id: userId,
        is_end_session: isEndSession
      });
      if (error) console.error('Error updating user activity:', error);
    };

    // Start/update session when the component mounts
    updateActivity();

    const handleActivity = () => {
      const now = new Date();
      const timeSinceLastActivity = now.getTime() - lastActivityRef.current.getTime();
      
      if (timeSinceLastActivity > 60000) { // Update if more than 1 minute has passed
        lastActivityRef.current = now;
        updateActivity();
      }
    };

    const activityEvents = ['mousedown', 'keydown', 'touchstart', 'scroll'];
    activityEvents.forEach(event => window.addEventListener(event, handleActivity));

    const interval = setInterval(handleActivity, 60000); // Check every minute

    return () => {
      activityEvents.forEach(event => window.removeEventListener(event, handleActivity));
      clearInterval(interval);

      // End the session when the component unmounts
      updateActivity(true);
    };
  }, [userId]);

  useEffect(() => {
    if (!userId) return;

    const handleBeforeUnload = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const headers = new Headers({
        'Content-Type': 'application/json',
        'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVtYnJwZWt0ZXlpa3dydW1za2VnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM3Mjc1MDcsImV4cCI6MjAzOTMwMzUwN30.xgS_w37QyF4qY4YIh6LxMOT0H4mPxK4XsfZeYZuStNI',
        'Authorization': `Bearer ${session?.access_token || ''}`
      });

      const blob = new Blob([JSON.stringify({ user_id: userId, is_end_session: true })], { type: 'application/json' });

      navigator.sendBeacon(
        'https://umbrpekteyikwrumskeg.supabase.co/rest/v1/rpc/update_user_activity',
        new Blob([JSON.stringify({ user_id: userId, is_end_session: true })], { type: 'application/json' })
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userId]);
};

export default useSessionTracking;