import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { ThemeProvider } from './ThemeContext';
import { HelmetProvider } from 'react-helmet-async';
import useSessionTracking from './useSessionTracking';

// Components
import Header from './components/Header';
import Hero from './components/Hero';
import Stats from './components/Stats';
import Features from './components/Features';
import Analysis from './components/Analysis';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import Competitions from './components/Competitions';
import AddCompetition from './components/AddCompetition';
import OpenCompetitions from './components/OpenCompetitions';
import Leaderboard from './components/Leaderboard';
import Achievements from './components/Achievements';
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile';
import Settings from './components/Settings';
import Layout from './components/Layout';
import BlogPage from './components/BlogPage';
import BlogPost from './components/BlogPost';
import ForumList from './components/ForumList';
import ForumPage from './components/ForumPage';
import PostPage from './components/PostPage';
import CreatePostForm from './components/CreatePostForm';
import EditPostForm from './components/EditPostForm';
import ScrollToTop from './components/ScrollToTop';

// Google Analytics
declare global {
  interface Window {
    dataLayer: any[];
    gtag?: (...args: any[]) => void;
  }
}

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();
  const [isGtagReady, setIsGtagReady] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-FDHJNS62C7`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any[]) {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', 'G-FDHJNS62C7');
      setIsGtagReady(true);
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isGtagReady && window.gtag) {
      window.gtag('config', 'G-FDHJNS62C7', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, isGtagReady]);

  return null;
};

// Banner Component
const Banner: React.FC = () => {
  return (
    <div style={{
      backgroundColor: '#9333EA',
      color: 'white',
      textAlign: 'center',
      padding: '8px',
      fontSize: '14px'
    }}>
      Currently in active development. We welcome all user feedback on the forum.
    </div>
  );
};

const HomePage: React.FC = () => {
  const pricingRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [location]);

  return (
    <>
      <Hero />
      <Stats />
      <Features />
      <Analysis />
      <div id="pricing" ref={pricingRef}>
        <Pricing />
      </div>
      <div id="faq" ref={faqRef}>
        <FAQ />
      </div>
      <ScrollToTop />
    </>
  );
};

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
      setUserId(user?.id);
    };
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsLoggedIn(!!session);
      setUserId(session?.user?.id);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  useSessionTracking(userId);

  if (isLoggedIn === null) {
    return <div>Loading...</div>;
  }

  return (
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          <GoogleAnalytics />
          <Banner />
          <AppContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

interface AppContentProps {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const AppContent: React.FC<AppContentProps> = ({ isLoggedIn, setIsLoggedIn }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const showHeaderFooter = !isLoggedIn || location.pathname === '/' || location.pathname.startsWith('/blog');

  const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/login" replace />;
  };

  const handleNavigation = (path: string) => {
    if (location.pathname === '/' && (path === '#pricing' || path === '#faq')) {
      const element = document.querySelector(path);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/' + path);
    }
  };

  return (
    <>
      {showHeaderFooter && <Header isLoggedIn={isLoggedIn} onLogout={() => setIsLoggedIn(false)} onNavigation={handleNavigation} />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login onLogin={() => setIsLoggedIn(true)} />} />
        <Route path="/signup" element={<SignUp onSignUp={() => setIsLoggedIn(true)} />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/profile/:username" element={<PublicProfile />} />

        <Route path="/dashboard" element={<ProtectedRoute element={<Layout><Dashboard /></Layout>} />} />
        <Route path="/competitions" element={<ProtectedRoute element={<Layout><Competitions /></Layout>} />} />
        <Route path="/add-competition" element={<ProtectedRoute element={<Layout><AddCompetition /></Layout>} />} />
        <Route path="/open-competitions" element={<ProtectedRoute element={<Layout><OpenCompetitions /></Layout>} />} />
        <Route path="/leaderboard" element={<ProtectedRoute element={<Layout><Leaderboard /></Layout>} />} />
        <Route path="/achievements" element={<ProtectedRoute element={<Layout><Achievements /></Layout>} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Layout><Profile /></Layout>} />} />
        <Route path="/settings" element={<ProtectedRoute element={<Layout><Settings /></Layout>} />} />

        <Route path="/forums" element={<ProtectedRoute element={<Layout><ForumList /></Layout>} />} />
        <Route path="/forum/:forumId" element={<ProtectedRoute element={<Layout><ForumPage /></Layout>} />} />
        <Route path="/post/:postId" element={<ProtectedRoute element={<Layout><PostPage /></Layout>} />} />
        <Route path="/create-post/:forumId" element={<ProtectedRoute element={<Layout><CreatePostForm forumId="" onPostCreated={() => {}} /></Layout>} />} />
        <Route path="/edit-post/:postId" element={<ProtectedRoute element={<Layout><EditPostForm /></Layout>} />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {showHeaderFooter && <Footer />}
    </>
  );
}; 

export default App;