import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { ChevronUp, ChevronDown, Edit3, Star, Trophy, Award, MessageSquare, ExternalLink } from 'lucide-react';

interface LeaderboardEntry {
  rank: number;
  username: string;
  entries: number;
  wins: number;
  achievements: number;
  forumActivity: number;
  totalPoints: number;
  avatar_url: string;
}

const pointAllocations = [
  { action: "Per entry", points: 1, icon: <Edit3 size={16} />, color: "bg-blue-500" },
  { action: "For adding a comp", points: 2, icon: <Star size={16} />, color: "bg-green-500" },
  { action: "For a win", points: 5, icon: <Trophy size={16} />, color: "bg-yellow-500" },
  { action: "Per achievement", points: 5, icon: <Award size={16} />, color: "bg-purple-500" },
  { action: "Per forum post or comment", points: 1, icon: <MessageSquare size={16} />, color: "bg-pink-500" },
];

const Leaderboard: React.FC = () => {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAllocation, setShowAllocation] = useState(false);
  const [userRank, setUserRank] = useState<number | null>(null);

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: leaderboardData, error: leaderboardError } = await supabase
        .from('leaderboard')
        .select('*')
        .not('user_id', 'is', null)
        .order('total_points', { ascending: false });

      if (leaderboardError) throw leaderboardError;

      const userIds = leaderboardData.map(entry => entry.user_id).filter(id => id !== null);
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id, avatar_url')
        .in('id', userIds);

      if (userError) throw userError;

      const avatarMap = new Map(userData.map(user => [user.id, user.avatar_url]));

      const formattedData = leaderboardData.map((entry, index) => ({
        rank: index + 1,
        username: entry.username,
        entries: entry.entries,
        wins: entry.wins,
        achievements: entry.achievements,
        forumActivity: entry.forum_posts + entry.forum_comments,
        totalPoints: entry.total_points,
        avatar_url: avatarMap.get(entry.user_id) || '/placeholder.svg?height=40&width=40',
      }));

      setLeaderboardData(formattedData);

      const currentUserRank = formattedData.findIndex(entry => entry.username === user.user_metadata.username) + 1;
      setUserRank(currentUserRank > 0 ? currentUserRank : null);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      setError('Failed to load leaderboard data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="p-4 text-center">Loading leaderboard data...</div>;
  if (error) return <div className="p-4 text-center text-red-500">Error: {error}</div>;

  return (
    <div className="w-full min-h-screen bg-[#EEF0FD] p-4">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg shadow-md border border-purple-200 p-4 mb-4">
          <h1 className="text-2xl font-bold text-purple-800 text-center">Leaderboard</h1>
          {userRank && (
            <p className="text-sm text-gray-600 text-center mt-2">
              You're currently ranked {userRank} out of {leaderboardData.length} users
            </p>
          )}
        </div>

        {/* Point Allocation */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
          <button
            className="flex items-center justify-between w-full p-4 text-lg font-semibold text-gray-800 hover:bg-gray-50 transition-colors duration-150"
            onClick={() => setShowAllocation(!showAllocation)}
          >
            <span>Point Allocation</span>
            {showAllocation ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </button>
          <div className={`transition-all duration-300 ease-in-out ${
            showAllocation ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden`}>
            <div className="p-4 space-y-3">
              {pointAllocations.map((allocation, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <span className={`p-2 rounded-full ${allocation.color} text-white`}>
                      {allocation.icon}
                    </span>
                    <span className="text-sm text-gray-600">{allocation.action}</span>
                  </div>
                  <span className="text-lg font-semibold text-gray-800">
                    {allocation.points} {allocation.points === 1 ? 'point' : 'points'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Leaderboard */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          {leaderboardData.map((entry, index) => (
            <div
              key={index}
              className="p-4 border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150"
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <div className="flex-shrink-0 w-8 text-center font-bold text-xl text-gray-500 mr-4">
                    {entry.rank}
                  </div>
                  <div className="flex-shrink-0">
                    <img src={entry.avatar_url} alt={entry.username} className="w-10 h-10 rounded-full" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-800">
                      <Link to={`/profile/${entry.username}`} className="hover:underline flex items-center">
                        {entry.username}
                        <ExternalLink size={16} className="ml-1" />
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <span className="text-2xl font-bold text-gray-800">{entry.totalPoints}</span>
                  <span className="text-sm text-gray-500 ml-1">points</span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 text-sm text-gray-500 mt-2 justify-items-center sm:flex sm:space-x-4 sm:justify-center">
                <span><Trophy size={16} className="inline mr-1 text-yellow-500" /> {entry.wins} wins</span>
                <span><Star size={16} className="inline mr-1 text-blue-500" /> {entry.entries} entries</span>
                <span><Award size={16} className="inline mr-1 text-purple-500" /> {entry.achievements} achievements</span>
                <span><MessageSquare size={16} className="inline mr-1 text-pink-500" /> {entry.forumActivity} posts</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;