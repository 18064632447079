import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import ReactConfetti from 'react-confetti';
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

interface Competition {
  id: string;
  title: string;
  status: 'entered' | 'ignored' | 'open';
  won: boolean;
  closing_date: string;
  created_at: string;
}

interface Message {
  type: 'success' | 'error';
  content: string;
}

const StatusIcon = ({ status, won }: { status: Competition['status'], won: boolean }) => {
  if (won) return <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88" stroke="#22C55E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 15C15.866 15 19 11.866 19 8V1H5V8C5 11.866 8.13401 15 12 15Z" stroke="#22C55E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>;
  switch (status) {
    case 'entered': return <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 12V22H4V12" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M22 7H2V12H22V7Z" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 22V7" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>;
    case 'ignored': return <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M15 9L9 15" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M9 9L15 15" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>;
    default: return <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10" stroke="#9333EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 6V12L16 14" stroke="#9333EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>;
  }
};

const Competitions: React.FC = () => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<Message | null>(null);

  useEffect(() => {
    fetchCompetitions();
  }, []);

  const fetchCompetitions = async () => {
    setIsLoading(true);
    setError(null);
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      setError("User not authenticated");
      setIsLoading(false);
      return;
    }

    const { data, error } = await supabase
      .from('user_competition_status')
      .select(`
        competition_id,
        status,
        won,
        competitions (
          id,
          title,
          closing_date,
          created_at
        )
      `)
      .eq('user_id', user.id);

    if (error) {
      console.error('Error fetching competitions:', error);
      setError("Failed to fetch competitions");
    } else if (data) {
      const formattedData: Competition[] = data.reduce((acc: Competition[], item: any) => {
        if (item.competitions && typeof item.competitions === 'object') {
          acc.push({
            id: item.competitions.id,
            title: item.competitions.title,
            status: item.status || 'open',
            won: item.won,
            closing_date: item.competitions.closing_date,
            created_at: item.competitions.created_at
          });
        }
        return acc;
      }, []);
      setCompetitions(formattedData);
    }
    setIsLoading(false);
  };

  const updateStatus = async (competitionId: string, newStatus: Competition['status'], won: boolean) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const { error } = await supabase
      .from('user_competition_status')
      .update({ 
        status: newStatus,
        won: won
      })
      .eq('user_id', user.id)
      .eq('competition_id', competitionId);

    if (error) {
      console.error('Error updating competition status:', error);
      setMessage({ type: 'error', content: "Failed to update competition status. Please try again." });
    } else {
      setCompetitions(competitions.map(comp =>
        comp.id === competitionId ? { ...comp, status: newStatus, won: won } : comp
      ));
      if (won) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
      }
      setMessage({ type: 'success', content: `Competition ${won ? 'marked as won' : `status updated to ${newStatus}`}.` });
    }

    // Clear message after 3 seconds
    setTimeout(() => setMessage(null), 3000);
  };

  const stats = {
    total: competitions.length,
    entered: competitions.filter(c => c.status === 'entered' && !c.won).length,
    won: competitions.filter(c => c.won).length,
    ignored: competitions.filter(c => c.status === 'ignored').length,
  };

  if (isLoading) {
    return <div>Loading competitions...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-[#EEF0FD] min-h-screen text-[#111827]">
      {showConfetti && <ReactConfetti />}
      <h1 className="text-3xl font-bold mb-6 text-[#111827]">My Competitions</h1>
      
      {message && (
        <div className={`mb-4 p-4 rounded-md ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {message.content}
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <StatCard title="Total Competitions" value={stats.total} icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 21V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V21L12 17.5L19 21Z" stroke="#9333EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>} color="#9333EA" />
        <StatCard title="Entered" value={stats.entered} icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 12V22H4V12" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M22 7H2V12H22V7Z" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 22V7" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>} color="#3B82F6" />
        <StatCard title="Won" value={stats.won} icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88" stroke="#22C55E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 15C15.866 15 19 11.866 19 8V1H5V8C5 11.866 8.13401 15 12 15Z" stroke="#22C55E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>} color="#22C55E" />
        <StatCard title="Ignored" value={stats.ignored} icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M15 9L9 15" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M9 9L15 15" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>} color="#EF4444" />
      </div>

      <Tabs defaultValue="all" className="space-y-4">
        <TabsList className="bg-[#9333EA] text-white w-full justify-start overflow-x-auto">
          <TabsTrigger value="all" className="data-[state=active]:bg-[#FFFFFF] data-[state=active]:text-[#9333EA]">All</TabsTrigger>
          <TabsTrigger value="entered" className="data-[state=active]:bg-[#FFFFFF] data-[state=active]:text-[#9333EA]">Entered</TabsTrigger>
          <TabsTrigger value="won" className="data-[state=active]:bg-[#FFFFFF] data-[state=active]:text-[#9333EA]">Won</TabsTrigger>
          <TabsTrigger value="ignored" className="data-[state=active]:bg-[#FFFFFF] data-[state=active]:text-[#9333EA]">Ignored</TabsTrigger>
        </TabsList>
        <TabsContent value="all" className="space-y-4">
          {competitions.map(comp => (
            <CompetitionCard key={comp.id} competition={comp} updateStatus={updateStatus} />
          ))}
        </TabsContent>
        <TabsContent value="entered" className="space-y-4">
          {competitions.filter(c => c.status === 'entered' && !c.won).map(comp => (
            <CompetitionCard key={comp.id} competition={comp} updateStatus={updateStatus} />
          ))}
        </TabsContent>
        <TabsContent value="won" className="space-y-4">
          {competitions.filter(c => c.won).map(comp => (
            <CompetitionCard key={comp.id} competition={comp} updateStatus={updateStatus} />
          ))}
</TabsContent>
        <TabsContent value="ignored" className="space-y-4">
          {competitions.filter(c => c.status === 'ignored').map(comp => (
            <CompetitionCard key={comp.id} competition={comp} updateStatus={updateStatus} />
          ))}
        </TabsContent>
      </Tabs>
    </div>
  );
};

function StatCard({ title, value, icon, color }: { title: string, value: number, icon: React.ReactNode, color: string }) {
  return (
    <Card className="bg-white">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <div className="text-2xl" style={{ color }}>{icon}</div>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold" style={{ color }}>{value}</div>
      </CardContent>
    </Card>
  );
}

function CompetitionCard({ competition, updateStatus }: { competition: Competition, updateStatus: (id: string, status: Competition['status'], won: boolean) => void }) {
  return (
    <Card className="bg-white overflow-hidden">
      <div className="flex items-center p-4">
        <div className="flex-shrink-0 mr-4">
          <StatusIcon status={competition.status} won={competition.won} />
        </div>
        <div className="flex-grow min-w-0">
          <h3 className="text-sm font-medium text-gray-900 truncate">
            {competition.title}
          </h3>
          <div className="flex items-center mt-1 text-xs text-gray-500">
            <svg className="flex-shrink-0 mr-1.5 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>Closes: {new Date(competition.closing_date).toLocaleDateString()}</span>
          </div>
        </div>
        <div className="flex-shrink-0 ml-4">
          <Badge 
            variant="secondary"
            className={`
              ${competition.won ? 'bg-[#22C55E]' : 
                competition.status === 'entered' ? 'bg-[#3B82F6]' : 
                competition.status === 'ignored' ? 'bg-[#EF4444]' : 
                'bg-[#9333EA]'} 
              text-white
            `}
          >
            {competition.won ? 'Won' : competition.status.charAt(0).toUpperCase() + competition.status.slice(1)}
          </Badge>
        </div>
      </div>
      <CardFooter className="bg-gray-50 px-4 py-3">
        <div className="flex flex-wrap justify-end gap-2">
          {!competition.won && competition.status !== 'entered' && (
            <Button size="sm" className="bg-[#3B82F6] text-white hover:bg-[#2563EB]" onClick={() => updateStatus(competition.id, 'entered', false)}>Mark as Entered</Button>
          )}
          {!competition.won && (
            <Button size="sm" className="bg-[#22C55E] text-white hover:bg-[#16A34A]" onClick={() => updateStatus(competition.id, competition.status, true)}>Mark as Won</Button>
          )}
          {competition.status !== 'ignored' && !competition.won && (
            <Button size="sm" variant="outline" className="text-[#EF4444] border-[#EF4444] hover:bg-[#FEE2E2]" onClick={() => updateStatus(competition.id, 'ignored', false)}>Ignore</Button>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}

export default Competitions;