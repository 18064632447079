import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import navLogo from './assets/navlogo.png';

const Footer: React.FC = () => (
  <footer className="bg-purple-900 text-white py-12">
    <div className="container mx-auto px-4">
      <div className="flex flex-col items-center md:items-start md:flex-row md:justify-between">
        <div className="mb-8 md:mb-0 text-center md:text-left">
          <img src={navLogo} alt="Sweepzy.io" className="h-8 mb-4 mx-auto md:mx-0" />
          <p className="text-sm max-w-xs">The ultimate resource for sweepstakes and compers.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          <div>
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-purple-300 text-sm">Home</Link></li>
              <li><Link to="/pricing" className="hover:text-purple-300 text-sm">Pricing</Link></li>
              <li><Link to="/faqs" className="hover:text-purple-300 text-sm">FAQs</Link></li>
              <li><Link to="/blog" className="hover:text-purple-300 text-sm">Blog</Link></li>
              <li><Link to="/guides" className="hover:text-purple-300 text-sm">Guides</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/terms" className="hover:text-purple-300 text-sm">Terms of Service</Link></li>
              <li><Link to="/privacy" className="hover:text-purple-300 text-sm">Privacy Policy</Link></li>
              <li><Link to="/cookies" className="hover:text-purple-300 text-sm">Cookie Policy</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Connect</h3>
            <div className="flex justify-center md:justify-start space-x-4">
              <a href="#" className="hover:text-purple-300" aria-label="Facebook">
                <Facebook size={20} />
              </a>
              <a href="#" className="hover:text-purple-300" aria-label="Twitter">
                <Twitter size={20} />
              </a>
              <a href="#" className="hover:text-purple-300" aria-label="Instagram">
                <Instagram size={20} />
              </a>
              <a href="#" className="hover:text-purple-300" aria-label="LinkedIn">
                <Linkedin size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-purple-800 text-center">
        <p className="text-sm">&copy; 2024 Sweepzy.io. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;