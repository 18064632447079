import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Make sure this import path is correct
import sweepzyLogo from './assets/sweepzylogo.png';

interface HeaderProps {
  isLoggedIn: boolean | null;
  onLogout: () => void;
  onNavigation: (path: string) => void;
}

interface BlogPost {
  id: string;
  title: string;
  slug: string;
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, onLogout, onNavigation }) => {
  const location = useLocation();
  const [compingGuides, setCompingGuides] = useState<BlogPost[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLLIElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchCompingGuides();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const fetchCompingGuides = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('id, title, slug')
      .eq('category_id', 'a4d7c00a-60b2-4939-8226-2e756f6a45a1')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching comping guides:', error);
    } else {
      setCompingGuides(data || []);
    }
  };

  const handleClick = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    onNavigation(path);
    setIsMenuOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setShowDropdown(false);
  };

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  return (
    <header className="bg-[#EEF0FD]">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src={sweepzyLogo} alt="Sweepzy.io" className="h-12" />
          </Link>
        </div>
        
        {/* Hamburger menu for mobile */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="w-10 h-10 flex items-center justify-center bg-[#9333EA] rounded-full focus:outline-none">
            {isMenuOpen ? (
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            )}
          </button>
        </div>

        {/* Navigation for desktop */}
        <nav className="hidden lg:block">
          <ul className="flex space-x-10">
            <li><Link to="/" className="font-sans font-bold text-base text-[#171938] hover:text-purple-600 transition-colors">Home</Link></li>
            <li><a href="/#pricing" onClick={handleClick('#pricing')} className="font-sans font-bold text-base text-[#171938] hover:text-purple-600 transition-colors">Pricing</a></li>
            <li><a href="/#faq" onClick={handleClick('#faq')} className="font-sans font-bold text-base text-[#171938] hover:text-purple-600 transition-colors">FAQs</a></li>
            <li><Link to="/blog" className="font-sans font-bold text-base text-[#171938] hover:text-purple-600 transition-colors">Blog</Link></li>
            <li ref={dropdownRef} className="relative">
              <button 
                onClick={toggleDropdown}
                className="font-sans font-bold text-base text-[#171938] hover:text-purple-600 transition-colors flex items-center"
              >
                Guides
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              {showDropdown && (
                <ul className="absolute left-0 mt-2 w-48 bg-[#9333EA] rounded-md shadow-lg z-10">
                  {compingGuides.map((guide) => (
                    <li key={guide.id}>
                      <Link
                        to={`/blog/${guide.slug}`}
                        className="block px-4 py-2 text-sm text-white hover:bg-purple-700 transition-colors"
                        onClick={() => setShowDropdown(false)}
                      >
                        {guide.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </nav>

        {/* Authentication buttons for desktop */}
        <div className="hidden lg:flex space-x-4">
          {isLoggedIn === true ? (
            <>
              <Link to="/dashboard" className="font-sans font-bold text-base text-white bg-purple-600 px-6 py-3 rounded hover:bg-purple-700 transition-colors">Dashboard</Link>
              <button onClick={onLogout} className="font-sans font-bold text-base text-purple-600 bg-white px-6 py-3 rounded border border-purple-600 hover:bg-purple-600 hover:text-white transition-colors">Logout</button>
            </>
          ) : isLoggedIn === false ? (
            <>
              <Link to="/login" className="font-sans font-bold text-base text-purple-600 bg-white px-6 py-3 rounded border border-purple-600 hover:bg-purple-600 hover:text-white transition-colors">Login</Link>
              <Link to="/signup" className="font-sans font-bold text-base text-white bg-purple-600 px-6 py-3 rounded hover:bg-purple-700 transition-colors">Sign Up</Link>
            </>
          ) : null}
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div ref={menuRef} className="lg:hidden fixed inset-0 bg-[#EFF1FD] text-[#9333EA] z-50 flex flex-col overflow-y-auto">
          <div className="flex justify-between items-center p-4">
            <Link to="/" onClick={() => setIsMenuOpen(false)}>
              <img src={sweepzyLogo} alt="Sweepzy.io" className="h-12" />
            </Link>
            <button onClick={toggleMenu} className="w-10 h-10 flex items-center justify-center bg-[#9333EA] rounded-full focus:outline-none">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <ul className="flex-grow flex flex-col p-4">
            <li><Link to="/" className="block py-3 pl-4 font-bold text-lg" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
            <li><a href="/#pricing" onClick={handleClick('#pricing')} className="block py-3 pl-4 font-bold text-lg">Pricing</a></li>
            <li><a href="/#faq" onClick={handleClick('#faq')} className="block py-3 pl-4 font-bold text-lg">FAQs</a></li>
            <li><Link to="/blog" className="block py-3 pl-4 font-bold text-lg" onClick={() => setIsMenuOpen(false)}>Blog</Link></li>
            <li>
              <button 
                onClick={toggleDropdown}
                className="flex items-center justify-between w-full py-3 pl-4 font-bold text-lg"
              >
                Guides
                <svg className={`w-4 h-4 mr-4 transform transition-transform ${showDropdown ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              {showDropdown && (
                <ul className="pl-8">
                  {compingGuides.map((guide) => (
                    <li key={guide.id}>
                      <Link
                        to={`/blog/${guide.slug}`}
                        className="block py-2 font-bold text-lg"
                        onClick={() => {
                          setShowDropdown(false);
                          setIsMenuOpen(false);
                        }}
                      >
                        {guide.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
          <div className="p-4">
            {isLoggedIn === true ? (
              <>
                <Link to="/dashboard" className="block w-full text-center py-3 font-bold text-lg text-white bg-purple-600 rounded mb-2" onClick={() => setIsMenuOpen(false)}>Dashboard</Link>
                <button onClick={() => { onLogout(); setIsMenuOpen(false); }} className="block w-full text-center py-3 font-bold text-lg text-purple-600 bg-white rounded border border-purple-600">Logout</button>
              </>
            ) : isLoggedIn === false ? (
              <>
                <Link to="/login" className="block w-full text-center py-3 font-bold text-lg text-purple-600 bg-white rounded border border-purple-600 mb-2" onClick={() => setIsMenuOpen(false)}>Login</Link>
                <Link to="/signup" className="block w-full text-center py-3 font-bold text-lg text-white bg-purple-600 rounded" onClick={() => setIsMenuOpen(false)}>Sign Up</Link>
              </>
            ) : null}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;