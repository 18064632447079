import React, { useState, useEffect } from 'react';
import { Search, CheckCircle, XCircle, LayoutGrid, List } from 'lucide-react';
import { supabase } from '../supabaseClient';

interface Competition {
  id: string;
  title: string;
  url: string;
  promoter: string;
  closing_date: string;
  end_time: string | null;
  instructions: string;
  advice: string;
  restrictions: string;
  competition_type: string;
  entry_method: string;
  prize_type: string;
  value: string;
  user_status?: 'entered' | 'ignored' | null;
  created_at: string;
  thumbnail_url: string;
}

const OpenCompetitions: React.FC = () => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPrize, setFilterPrize] = useState('');
  const [filterEntryMethod, setFilterEntryMethod] = useState('');
  const [filterPrizeType, setFilterPrizeType] = useState('');
  const [filterClosingToday, setFilterClosingToday] = useState(false);
  const [filterAddedToday, setFilterAddedToday] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCompetitions, setTotalCompetitions] = useState(0);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const competitionsPerPage = 20;

  useEffect(() => {
    fetchCompetitions();
  }, [currentPage, searchTerm, filterPrize, filterEntryMethod, filterPrizeType, filterClosingToday, filterAddedToday]);

  const fetchCompetitions = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const now = new Date();
    const today = now.toISOString().split('T')[0];
    let query = supabase
      .from('competitions')
      .select('*, user_competition_status!left(status)', { count: 'exact' })
      .gte('closing_date', today)
      .order('closing_date', { ascending: true });

    if (searchTerm) {
      query = query.or(`title.ilike.%${searchTerm}%,promoter.ilike.%${searchTerm}%`);
    }

    if (filterPrize) {
      query = query.gte('value', filterPrize);
    }

    if (filterEntryMethod) {
      query = query.eq('entry_method', filterEntryMethod);
    }

    if (filterPrizeType) {
      query = query.eq('prize_type', filterPrizeType);
    }

    if (filterClosingToday) {
      query = query.eq('closing_date', today);
    }

    if (filterAddedToday) {
      query = query.gte('created_at', `${today}T00:00:00`).lte('created_at', `${today}T23:59:59`);
    }

    const { data, count, error } = await query
      .range((currentPage - 1) * competitionsPerPage, currentPage * competitionsPerPage - 1);

    if (error) {
      console.error('Error fetching competitions:', error);
    } else {
      const filteredData = data?.filter(comp => 
        !comp.user_competition_status || comp.user_competition_status.user_id !== user.id
      ) || [];
      setCompetitions(filteredData);
      setTotalCompetitions(count || 0);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const formatTime = (timeString: string | null) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  const handleCompetitionAction = async (competitionId: string, action: 'entered' | 'ignored') => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const { error } = await supabase
      .from('user_competition_status')
      .upsert({
        user_id: user.id,
        competition_id: competitionId,
        status: action
      });

    if (error) {
      console.error('Error updating competition status:', error);
    } else {
      setCompetitions(competitions.filter(comp => comp.id !== competitionId));
    }
  };

  const totalPages = Math.ceil(totalCompetitions / competitionsPerPage);

  const renderCompetitionCard = (competition: Competition) => (
    <div key={competition.id} className="bg-white p-4 rounded-lg shadow-md border border-purple-300 flex flex-col justify-between h-full">
      <div>
        <h2 className="text-lg font-semibold mb-2 line-clamp-2">{competition.title}</h2>
        <p className="text-purple-600 mb-2 text-sm font-semibold">
          Closes: {formatDate(competition.closing_date)} {formatTime(competition.end_time)}
        </p>
        {competition.thumbnail_url && (
          <img src={competition.thumbnail_url} alt={competition.title} className="w-full h-32 object-cover mb-2 rounded" />
        )}
        <div className="flex flex-wrap gap-1 mb-2">
          <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">{competition.promoter || 'Unknown'}</span>
          <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">{competition.competition_type}</span>
          {competition.value && (
            <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">{competition.value}</span>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <div className="flex space-x-2">
          <button 
            onClick={() => handleCompetitionAction(competition.id, 'entered')}
            className="w-8 h-8 bg-purple-500 rounded-full flex items-center justify-center text-white"
          >
            <CheckCircle size={16} />
          </button>
          <button 
            onClick={() => handleCompetitionAction(competition.id, 'ignored')}
            className="w-8 h-8 bg-black rounded-full flex items-center justify-center text-white"
          >
            <XCircle size={16} />
          </button>
        </div>
        <a 
          href={competition.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="flex items-center justify-center px-3 py-1 bg-purple-500 text-white text-sm rounded"
        >
          Enter
        </a>
      </div>
    </div>
  );

  const renderCompetitionList = () => (
    <div className="overflow-x-auto">
      <table className="w-full bg-white">
        <thead className="bg-[#111827] text-white">
          <tr>
            <th className="px-2 py-2 text-left text-sm">Title</th>
            <th className="px-2 py-2 text-left text-sm hidden sm:table-cell">Closes</th>
            <th className="px-2 py-2 text-left text-sm hidden md:table-cell">Type</th>
            <th className="px-2 py-2 text-left text-sm hidden lg:table-cell">Value</th>
            <th className="px-2 py-2 text-left text-sm">Actions</th>
          </tr>
        </thead>
        <tbody>
          {competitions.map(competition => (
            <tr key={competition.id} className="border-b">
              <td className="px-2 py-2 text-sm">
                <div className="font-medium">{competition.title}</div>
                <div className="text-xs text-gray-500 sm:hidden">
                  Closes: {formatDate(competition.closing_date)}
                </div>
              </td>
              <td className="px-2 py-2 text-sm hidden sm:table-cell">
                {formatDate(competition.closing_date)} {formatTime(competition.end_time)}
              </td>
              <td className="px-2 py-2 text-sm hidden md:table-cell">{competition.competition_type}</td>
              <td className="px-2 py-2 text-sm hidden lg:table-cell">{competition.value || 'N/A'}</td>
              <td className="px-2 py-2">
                <div className="flex space-x-1">
                  <button 
                    onClick={() => handleCompetitionAction(competition.id, 'entered')}
                    className="w-6 h-6 bg-green-500 rounded-full flex items-center justify-center text-white"
                  >
                    <CheckCircle size={14} />
                  </button>
                  <button 
                    onClick={() => handleCompetitionAction(competition.id, 'ignored')}
                    className="w-6 h-6 bg-red-500 rounded-full flex items-center justify-center text-white"
                  >
                    <XCircle size={14} />
                  </button>
                  <a 
                    href={competition.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="flex items-center justify-center px-2 py-1 bg-[#111827] text-white text-xs rounded"
                  >
                    Enter
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="p-4 sm:p-6 w-full max-w-full overflow-x-hidden">
      <div className="mb-4 space-y-2">
        <div className="relative">
          <input
            type="text"
            placeholder="Search competitions..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="w-full p-2 pl-10 border rounded bg-white"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <div className="flex flex-wrap gap-2">
          <select
            value={filterPrize}
            onChange={(e) => {
              setFilterPrize(e.target.value);
              setCurrentPage(1);
            }}
            className="p-2 border rounded bg-white text-sm"
          >
            <option value="">All Prizes</option>
            <option value="100">£100+</option>
            <option value="500">£500+</option>
            <option value="1000">£1000+</option>
          </select>
          <select
            value={filterEntryMethod}
            onChange={(e) => {
              setFilterEntryMethod(e.target.value);
              setCurrentPage(1);
            }}
            className="p-2 border rounded bg-white text-sm"
          >
            <option value="">All Entry Methods</option>
            <option value="Website">Website</option>
            <option value="Social Media">Social Media</option>
            <option value="Email">Email</option>
            <option value="SMS">SMS</option>
            <option value="Post">Post</option>
            <option value="In-Store">In-Store</option>
          </select>
          <select
            value={filterPrizeType}
            onChange={(e) => {
              setFilterPrizeType(e.target.value);
              setCurrentPage(1);
            }}
            className="p-2 border rounded bg-white text-sm"
          >
            <option value="">All Prize Types</option>
            <option value="Cash Prize">Cash Prize</option>
            <option value="Holiday">Holiday</option>
            <option value="Electronics">Electronics</option>
            <option value="Gift Card">Gift Card</option>
            <option value="Car">Car</option>
            <option value="Experience">Experience</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="flex flex-wrap gap-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={filterClosingToday}
              onChange={(e) => {
                setFilterClosingToday(e.target.checked);
                setCurrentPage(1);
              }}
              className="mr-2"
            />
            <span className="text-sm">Closing Today</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={filterAddedToday}
              onChange={(e) => {
                setFilterAddedToday(e.target.checked);
                setCurrentPage(1);
              }}
              className="mr-2"
            />
            <span className="text-sm">Added Today</span>
          </label>
          <button
            onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
            className="p-2 bg-[#111827] text-white rounded hover:bg-opacity-90 transition duration-300"
          >
            {viewMode === 'grid' ? <List size={20} /> : <LayoutGrid size={20} />}
          </button>
        </div>
      </div>

      {viewMode === 'grid' ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {competitions.map(renderCompetitionCard)}
        </div>
      ) : (
        renderCompetitionList()
      )}

      {competitions.length === 0 && (
        <p className="text-center text-gray-500 mt-6">No competitions found matching your criteria.</p>
      )}

      {totalPages > 1 && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-[#111827] text-white rounded mr-2 disabled:bg-gray-300 text-sm"
          >
            Previous
          </button>
          <span className="px-4 py-2 text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}disabled={currentPage === totalPages}
            className="px-4 py-2 bg-[#111827] text-white rounded ml-2 disabled:bg-gray-300 text-sm"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default OpenCompetitions;