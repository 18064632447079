import React from 'react';

const BlogHero: React.FC = () => {
  return (
    <div className="bg-[#171938] text-white py-20">
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Welcome to Our Blog</h1>
        <p className="text-xl md:text-2xl">
          Start your blog today and join a community of writers and readers who are passionate about sharing their stories and ideas. We offer everything you need to get started, from helpful tips and tutorials.
        </p>
      </div>
    </div>
  );
};

export default BlogHero;