import React from 'react';

interface BadgeProps {
  variant?: 'default' | 'secondary';
  className?: string;
  children: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({ variant = 'default', className = '', children }) => {
  const baseClasses = 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium';
  const variantClasses = variant === 'default' 
    ? 'bg-[#9333EA] text-white' 
    : 'bg-gray-100 text-gray-800';

  return (
    <span className={`${baseClasses} ${variantClasses} ${className}`}>
      {children}
    </span>
  );
};

export default Badge;