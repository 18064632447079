import React, { useState, useEffect } from 'react'
import { Card, CardContent } from "./ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { Trophy, Award, Clock, CalendarDays, Zap, Ban, MessageSquare, UserPlus, Gift, Ticket } from 'lucide-react'
import { supabase } from '../supabaseClient'

interface DashboardStats {
  activeCompetitions: number;
  competitionsWon: number;
  totalEntries: number;
  compsAddedToday: number;
  compsAddedThisMonth: number;
  compsAddedLastMonth: number;
  totalEnteredThisMonth: number;
  totalIgnoredThisMonth: number;
}

interface ActivityItem {
  type: string;
  name: string;
  date: string;
  icon: JSX.Element;
}

interface ActivityData {
  date: string;
  entries: number;
  ignored: number;
  added: number;
}

const COLORS = ['#9333EA', '#B366F2', '#D399FF', '#E6CCFF']

export default function Dashboard() {
  const [stats, setStats] = useState<DashboardStats>({
    activeCompetitions: 0,
    competitionsWon: 0,
    totalEntries: 0,
    compsAddedToday: 0,
    compsAddedThisMonth: 0,
    compsAddedLastMonth: 0,
    totalEnteredThisMonth: 0,
    totalIgnoredThisMonth: 0,
  })
  const [recentActivity, setRecentActivity] = useState<ActivityItem[]>([])
  const [activityData, setActivityData] = useState<ActivityData[]>([])
  const [categoryData, setCategoryData] = useState<{ name: string; value: number }[]>([])
  const [winLossData, setWinLossData] = useState<{ name: string; value: number }[]>([])
  const [completionData, setCompletionData] = useState<{ name: string; value: number }[]>([])
  const [selectedMonth, setSelectedMonth] = useState('current')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true)
      try {
        const { data: { user } } = await supabase.auth.getUser()
        if (!user) throw new Error('No user logged in')
  
        // Fetch dashboard stats
        const stats = await fetchStats(user.id)
        setStats(stats)
  
        // Fetch recent activity
        const activity = await fetchRecentActivity(user.id)
        setRecentActivity(activity)
  
        // Fetch category data
        const categories = await fetchCategoryData(user.id)
        setCategoryData(categories)
  
        // Fetch win/loss data
        const winLoss = await fetchWinLossData(user.id)
        setWinLossData(winLoss)
  
        // Fetch completion data
        const completion = await fetchCompletionData(user.id)
        setCompletionData(completion)
  
      } catch (error) {
        console.error('Error fetching dashboard data:', error)
        // Optionally, you can set an error state here to display to the user
        // setError('Failed to load dashboard data. Please try again later.')
      } finally {
        setLoading(false)
      }
    }
  
    fetchDashboardData()
  }, []) // Run once on component mount
  
  useEffect(() => {
    const fetchActivityDataForMonth = async () => {
      setLoading(true)
      try {
        await fetchActivityData(selectedMonth)
      } catch (error) {
        console.error('Error fetching activity data:', error)
        // Optionally, you can set an error state here
        // setError('Failed to load activity data. Please try again later.')
      } finally {
        setLoading(false)
      }
    }
  
    fetchActivityDataForMonth()
  }, [selectedMonth])

  const fetchDashboardData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user logged in')

      const stats = await fetchStats(user.id)
      setStats(stats)

      const activity = await fetchRecentActivity(user.id)
      setRecentActivity(activity)

      const categories = await fetchCategoryData(user.id)
      setCategoryData(categories)

      const winLoss = await fetchWinLossData(user.id)
      setWinLossData(winLoss)

      const completion = await fetchCompletionData(user.id)
      setCompletionData(completion)

    } catch (error) {
      console.error('Error fetching dashboard data:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStats = async (userId: string): Promise<DashboardStats> => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)

    const { count: activeCompetitions, error: activeError } = await supabase
      .from('competitions')
      .select('id', { count: 'exact', head: true })
      .gte('closing_date', today.toISOString())

    const { count: wonCompetitions, error: wonError } = await supabase
      .from('user_competition_status')
      .select('id', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('status', 'entered')
      .eq('won', true)

    const { count: totalEntries, error: entriesError } = await supabase
      .from('user_competition_status')
      .select('id', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('status', 'entered')

    const { count: compsAddedToday, error: todayError } = await supabase
      .from('competitions')
      .select('id', { count: 'exact', head: true })
      .gte('created_at', today.toISOString())

    const { count: compsAddedThisMonth, error: thisMonthError } = await supabase
      .from('competitions')
      .select('id', { count: 'exact', head: true })
      .gte('created_at', firstDayOfMonth.toISOString())

    const { count: compsAddedLastMonth, error: lastMonthError } = await supabase
      .from('competitions')
      .select('id', { count: 'exact', head: true })
      .gte('created_at', firstDayOfLastMonth.toISOString())
      .lt('created_at', lastDayOfLastMonth.toISOString())

    const { count: enteredThisMonth, error: enteredThisMonthError } = await supabase
      .from('user_competition_status')
      .select('id', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('status', 'entered')
      .gte('created_at', firstDayOfMonth.toISOString())

    const { count: ignoredThisMonth, error: ignoredThisMonthError } = await supabase
      .from('user_competition_status')
      .select('id', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('status', 'ignored')
      .gte('created_at', firstDayOfMonth.toISOString())

    if (activeError || wonError || entriesError || todayError || thisMonthError || lastMonthError || enteredThisMonthError || ignoredThisMonthError) {
      throw new Error('Error fetching stats')
    }

    return {
      activeCompetitions: activeCompetitions ?? 0,
      competitionsWon: wonCompetitions ?? 0,
      totalEntries: totalEntries ?? 0,
      compsAddedToday: compsAddedToday ?? 0,
      compsAddedThisMonth: compsAddedThisMonth ?? 0,
      compsAddedLastMonth: compsAddedLastMonth ?? 0,
      totalEnteredThisMonth: enteredThisMonth ?? 0,
      totalIgnoredThisMonth: ignoredThisMonth ?? 0,
    }
  }

  const fetchRecentActivity = async (userId: string): Promise<ActivityItem[]> => {
    const { data: recentEntries, error: entriesError } = await supabase
      .from('user_competition_status')
      .select('status, created_at, competitions(title)')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })
      .limit(5)
  
    const { data: recentPosts, error: postsError } = await supabase
      .from('posts')
      .select('title, created_at, forums(name)')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })
      .limit(5)
  
    if (entriesError || postsError) {
      throw new Error('Error fetching recent activity')
    }
  
    const activity: ActivityItem[] = [
      ...(recentEntries?.map(entry => ({
        type: entry.status === 'entered' ? 'Entered Competition' : 'Ignored Competition',
        name: entry.competitions?.[0]?.title || '',
        date: new Date(entry.created_at).toLocaleString(),
        icon: entry.status === 'entered' ? <Gift size={16} /> : <Ban size={16} />,
      })) ?? []),
      ...(recentPosts?.map(post => ({
        type: 'Posted on Forum',
        name: `${post.title} in ${post.forums?.[0]?.name || ''}`,
        date: new Date(post.created_at).toLocaleString(),
        icon: <MessageSquare size={16} />,
      })) ?? []),
    ]
  
    return activity.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, 5)
  }

  const fetchActivityData = async (period: string) => {
    const today = new Date()
    let startDate: Date
    let endDate: Date

    switch (period) {
      case 'current':
        startDate = new Date(today.getFullYear(), today.getMonth(), 1)
        endDate = today
        break
      case 'last':
        endDate = new Date(today.getFullYear(), today.getMonth(), 0)
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1)
        break
      case 'twoMonthsAgo':
        endDate = new Date(today.getFullYear(), today.getMonth() - 1, 0)
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1)
        break
      default:
        throw new Error('Invalid period')
    }

    const { data: { user } } = await supabase.auth.getUser()
    if (!user) throw new Error('No user logged in')

    const { data: activityData, error } = await supabase
      .from('user_competition_status')
      .select('status, created_at')
      .eq('user_id', user.id)
      .gte('created_at', startDate.toISOString())
      .lte('created_at', endDate.toISOString())
      .order('created_at', { ascending: true })

    if (error) throw error

    const { data: addedCompetitions, error: addedError } = await supabase
      .from('competitions')
      .select('created_at')
      .gte('created_at', startDate.toISOString())
      .lte('created_at', endDate.toISOString())
      .order('created_at', { ascending: true })

    if (addedError) throw addedError

    const aggregatedData: { [key: string]: ActivityData } = {}

    activityData?.forEach(item => {
      const date = new Date(item.created_at).toISOString().split('T')[0]
      if (!aggregatedData[date]) {
        aggregatedData[date] = { date, entries: 0, ignored: 0, added: 0 }
      }
      if (item.status === 'entered') {
        aggregatedData[date].entries++
      } else if (item.status === 'ignored') {
        aggregatedData[date].ignored++
      }
    })

    addedCompetitions?.forEach(item => {
      const date = new Date(item.created_at).toISOString().split('T')[0]
      if (!aggregatedData[date]) {
        aggregatedData[date] = { date, entries: 0, ignored: 0, added: 0 }
      }
      aggregatedData[date].added++
    })

    setActivityData(Object.values(aggregatedData))
  }

  const fetchCategoryData = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_competition_status')
      .select('competitions(category)')
      .eq('user_id', userId)
      .eq('status', 'entered')
  
    if (error) throw error
  
    const categories: { [key: string]: number } = {}
    data?.forEach(item => {
      const category = item.competitions?.[0]?.category
      if (category) {
        categories[category] = (categories[category] || 0) + 1
      }
    })
  
    return Object.entries(categories)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 5) // Limit to top 5 categories
  }

  const fetchWinLossData = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_competition_status')
      .select('won')
      .eq('user_id', userId)
      .eq('status', 'entered')
  
    if (error) throw error
  
    const wins = data?.filter(item => item.won).length ?? 0
    const losses = data?.filter(item => !item.won).length ?? 0
  
    return [
      { name: 'Wins', value: wins },
      { name: 'Losses', value: losses },
    ]
  }

  const fetchCompletionData = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_competition_status')
      .select('status')
      .eq('user_id', userId)
  
    if (error) throw error
  
    const completed = data?.filter(item => item.status === 'entered').length ?? 0
    const incomplete = data?.filter(item => item.status === 'ignored').length ?? 0
  
    return [
      { name: 'Completed', value: completed },
      { name: 'Incomplete', value: incomplete },
    ]
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    )
  }

  return (
    <div className="p-4 md:p-8 bg-[#EEF0FD] min-h-screen">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-[#111827]">Competition Dashboard</h1>
      
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-8">
        <StatCard title="Active Competitions" value={stats.activeCompetitions.toString()} color="text-[#9333EA]" icon={<Trophy size={20} />} />
        <StatCard title="Competitions Won" value={stats.competitionsWon.toString()} color="text-green-600" icon={<Award size={20} />} />
        <StatCard title="Alltime Entries" value={stats.totalEntries.toString()} color="text-blue-600" icon={<Zap size={20} />} />
        <StatCard title="Comps Added Today" value={stats.compsAddedToday.toString()} color="text-orange-600" icon={<Clock size={20} />} />
        <StatCard title="Comps Added This Month" value={stats.compsAddedThisMonth.toString()} color="text-[#9333EA]" icon={<CalendarDays size={20} />} /><StatCard title="Comps Added Last Month" value={stats.compsAddedLastMonth.toString()} color="text-purple-400" icon={<CalendarDays size={20} />} />
        <StatCard title="Entered This Month" value={stats.totalEnteredThisMonth.toString()} color="text-blue-600" icon={<UserPlus size={20} />} />
        <StatCard title="Ignored This Month" value={stats.totalIgnoredThisMonth.toString()} color="text-red-600" icon={<Ban size={20} />} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <Card className="bg-white">
          <CardContent className="pt-6">
            <h2 className="text-xl font-semibold mb-4 text-[#111827]">Activity Breakdown</h2>
            <div className="mb-4">
              <Select onValueChange={setSelectedMonth} defaultValue={selectedMonth}>
                <SelectTrigger className="w-full sm:w-auto">
                  <SelectValue placeholder="Select month" />
                </SelectTrigger>
                <SelectContent className="bg-[#EEF0FD]">
                  <SelectItem value="current">Current Month</SelectItem>
                  <SelectItem value="last">Last Month</SelectItem>
                  <SelectItem value="twoMonthsAgo">Two Months Ago</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <Tabs defaultValue="entries">
              <TabsList className="mb-4 flex flex-wrap">
                <TabsTrigger value="entries" className="flex-grow">Entries</TabsTrigger>
                <TabsTrigger value="ignored" className="flex-grow">Ignored</TabsTrigger>
                <TabsTrigger value="added" className="flex-grow">Added</TabsTrigger>
              </TabsList>
              <TabsContent value="entries">
                <ActivityChart data={activityData} dataKey="entries" />
              </TabsContent>
              <TabsContent value="ignored">
                <ActivityChart data={activityData} dataKey="ignored" />
              </TabsContent>
              <TabsContent value="added">
                <ActivityChart data={activityData} dataKey="added" />
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>

        <Card className="bg-white">
          <CardContent className="pt-6">
            <h2 className="text-xl font-semibold mb-4 text-[#111827]">Additional Stats</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h3 className="text-sm font-semibold text-center mb-2 text-[#111827]">Competitions by Category</h3>
                <SmallPieChart data={categoryData} />
                <div className="flex flex-wrap justify-center gap-2 mt-2">
                  {categoryData.map((entry, index) => (
                    <span key={entry.name} className="flex items-center text-xs">
                      <span className="w-3 h-3 inline-block mr-1" style={{backgroundColor: COLORS[index % COLORS.length]}}></span>
                      {entry.name}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-center mb-2 text-[#111827]">Win/Loss Ratio</h3>
                <SmallPieChart data={winLossData} />
                <div className="flex justify-center space-x-2 mt-2">
                  <span className="flex items-center text-xs"><span className="w-3 h-3 inline-block mr-1 bg-[#9333EA]"></span>Wins</span>
                  <span className="flex items-center text-xs"><span className="w-3 h-3 inline-block mr-1 bg-[#B366F2]"></span>Losses</span>
                </div>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-center mb-2 text-[#111827]">Completion Rate</h3>
                <SmallPieChart data={completionData} />
                <div className="flex justify-center space-x-2 mt-2">
                  <span className="flex items-center text-xs"><span className="w-3 h-3 inline-block mr-1 bg-[#9333EA]"></span>Completed</span>
                  <span className="flex items-center text-xs"><span className="w-3 h-3 inline-block mr-1 bg-[#B366F2]"></span>Incomplete</span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card className="bg-white">
        <CardContent className="pt-6">
          <h2 className="text-xl font-semibold mb-4 text-[#111827]">Recent Activity</h2>
          <Tabs defaultValue="all">
            <TabsList className="mb-4 flex flex-wrap">
              <TabsTrigger value="all" className="flex-grow">All</TabsTrigger>
              <TabsTrigger value="entered" className="flex-grow">Entered</TabsTrigger>
              <TabsTrigger value="ignored" className="flex-grow">Ignored</TabsTrigger>
              <TabsTrigger value="forum" className="flex-grow">Forum</TabsTrigger>
            </TabsList>
            <TabsContent value="all">
              <ActivityList activities={recentActivity} />
            </TabsContent>
            <TabsContent value="entered">
              <ActivityList activities={recentActivity.filter(a => a.type === 'Entered Competition')} />
            </TabsContent>
            <TabsContent value="ignored">
              <ActivityList activities={recentActivity.filter(a => a.type === 'Ignored Competition')} />
            </TabsContent>
            <TabsContent value="forum">
              <ActivityList activities={recentActivity.filter(a => a.type === 'Posted on Forum')} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  )
}

function StatCard({ title, value, color, icon }: { title: string, value: string, color: string, icon: JSX.Element }) {
  return (
    <Card className="bg-white">
      <CardContent className="p-4">
        <div className="flex items-center space-x-3 mb-2">
          <div className={`p-2 rounded-full ${color} bg-opacity-10`}>
            {icon}
          </div>
          <div className={`text-lg font-bold ${color}`}>{value}</div>
        </div>
        <div className="text-xs font-medium text-[#111827]">{title}</div>
      </CardContent>
    </Card>
  )
}

function ActivityChart({ data, dataKey }: { data: ActivityData[], dataKey: 'entries' | 'ignored' | 'added' }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={dataKey} stroke="#9333EA" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  )
}

function SmallPieChart({ data }: { data: { name: string; value: number }[] }) {
  // Filter out any items with zero value
  const filteredData = data.filter(item => item.value > 0);

  if (filteredData.length === 0) {
    return <div className="text-center text-gray-500">No data available</div>;
  }

  // If there's only one data point, add a dummy point to make the pie visible
  const chartData = filteredData.length === 1 
    ? [...filteredData, { name: 'Other', value: 0.00001 }] 
    : filteredData;

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value, name, props) => {
          if (name === 'Other' && Number(value) === 0.00001) return ['', ''];
          return [`${value} (${((Number(value) / filteredData.reduce((sum, item) => sum + item.value, 0)) * 100).toFixed(1)}%)`, name];
        }} />
        <Legend layout="vertical" align="right" verticalAlign="middle" />
      </PieChart>
    </ResponsiveContainer>
  );
}

function ActivityList({ activities }: { activities: ActivityItem[] }) {
  return (
    <ul className="space-y-4">
      {activities.map((activity, index) => (
        <li key={index} className="bg-white p-4 rounded-lg shadow border-l-4 border-[#9333EA] flex items-start">
          <div className="mr-3 mt-1 p-2 bg-[#9333EA] bg-opacity-10 rounded-full text-[#9333EA]">
            {activity.icon}
          </div>
          <div>
            <h3 className="font-semibold text-[#111827]">{activity.type}</h3>
            <p className="text-sm text-[#111827]">{activity.name}</p>
            <p className="text-xs text-gray-400">{activity.date}</p>
          </div>
        </li>
      ))}
    </ul>
  )
}

