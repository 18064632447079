import React from 'react';
import { Link } from 'react-router-dom';
import heroImage from './assets/winner.webp';

const Hero: React.FC = () => (
  <section className="bg-[#EFF1FD] py-12 sm:py-20">
    <div className="container mx-auto px-4">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/2 lg:pr-12 mb-6 lg:mb-0">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 font-sans">The Ultimate Resource For Compers & Sweepstakers</h1>
          <p className="text-lg sm:text-xl mb-6">Effortlessly find, enter and track the latest competitions with our platform. Use our pro-features to apply for competitions automatically. Join the community and start winning prizes today!</p>
          <div className="flex flex-col sm:flex-row items-center">
            <Link to="/signup" className="w-full sm:w-auto bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors text-center">Sign Up Now</Link>
          </div>
          <p className="text-sm mt-3 text-gray-600">*No credit card required</p>
        </div>
        <div className="w-full lg:w-5/12">
          <img src={heroImage} alt="Hero illustration" className="w-full max-w-md mx-auto" />
        </div>
      </div>
    </div>
  </section>
);

export default Hero;